import React from 'react';
import { projects } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Projects.css'


function Projects(props) {
    if (!projects.length) return null

    return (
      <section id='projects' className='section projects'>
        <h2 className='section__title'>Projects</h2> 
            
        <div className='projects__grid'>
          {projects.map((project) => (
            <ProjectContainer project={project} />
          ))}
        </div>
      </section>
    )
  }






export default Projects